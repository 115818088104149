import React, { useCallback, useState } from "react";
import { Helmet } from "react-helmet";
import { Heading, Image, Text, Flex, Box } from "rebass";
import atIcon from "../../assets/images/ios-at.svg";
import { BrandButton, BrandButtonA } from "../../components/BrandButton";
import Page from "../../components/Page";
import HeroLayoutEmpty from "../../components/HeroLayoutEmpty";
import HeroGridCard from "../../components/HeroGridCard";
import PageSection from "../../components/PageSection";
import HeroGrid from "../../components/HeroGrid";
import IndexLayout from "../../layouts";
import { FetchState, makeGraphqlRequest } from "../../lib/api";
import * as globalStyles from "../../styles/Global.module.css";
import * as styles from "./Verify.module.css";
import { RequestEmailConfirmation } from "../../lib/mutations";

const VerifyEmailPage = () => {
  const [email, setEmail] = useState("");

  const [{ isError, isSuccessful }, setState] = useState<FetchState>({
    isError: false,
    isLoading: false,
    isSuccessful: false,
  });

  const onResendClick = useCallback(async () => {
    setState({
      isError: false,
      isLoading: true,
      isSuccessful: false,
    });

    const data = await makeGraphqlRequest("auth", {
      query: RequestEmailConfirmation,
      variables: { email },
    });

    if (!data?.data?.requestEmailConfirmation?.success) {
      setState({
        isError: true,
        isLoading: false,
        isSuccessful: false,
      });

      return;
    }

    setState({
      isError: false,
      isLoading: false,
      isSuccessful: true,
    });
  }, [email]);

  return (
    <IndexLayout>
      <Page>
        <HeroGrid
          style={{ position: "relative", overflow: "hidden", rowGap: "0" }}
        >
          <Helmet>
            <title>Link Expired | Skodel</title>
            <meta property="og:title" content="Verify Email | Skodel" />
          </Helmet>
          <PageSection
            className={styles.heroAboutUs}
            color="white"
            id="top"
            css={{ minHeight: "100vh" }}
          >
            <Box className={`${styles.heroAboutUsStripes} brandStripes`}>
              <Box className={styles.heroAboutUsStripesInner}>
                <Box className={styles.heroAboutUsStripe1} />
                <Box className={styles.heroAboutUsStripe2} />
                <Box className={styles.heroAboutUsStripe3} />
                <Box className={styles.heroAboutUsStripe4} />
              </Box>
            </Box>
            <HeroGridCard style={{ margin: "208px auto 0" }}>
              <HeroLayoutEmpty>
                <Flex justifyContent="center">
                  <Heading
                    fontSize={2}
                    color="rgb(91, 157, 233)"
                    justifyContent="center"
                    style={{ display: "flex", borderRadius: "20px" }}
                    p={2}
                    px={3}
                    bg="rgba(70, 156, 240, 0.1)"
                  >
                    Link Expired
                  </Heading>
                </Flex>
                <Heading
                  style={{
                    maxWidth: "744px",
                    margin: "10px auto 0",
                    fontSize: "42px",
                    lineHeight: "58px",
                    color: "#222A41",
                    textAlign: "center",
                  }}
                >
                  Verify Email
                </Heading>
                <Text
                  style={{
                    maxWidth: "716px",
                    margin: "24px auto 0",
                    fontSize: "17px",
                    lineHeight: "30px",
                    color: "#7b7b7b",
                    textAlign: "center",
                  }}
                >
                  The email verification link has expired. Please enter the
                  email address associated with your account, and we&apos;ll
                  send you a new one.
                </Text>
                <Flex pt="5" justifyContent="center">
                  <Box
                    style={{
                      position: "relative",
                      display: "grid",
                      gridTemplateColumns: "1fr",
                      marginBottom: "25px",
                    }}
                  >
                    <input
                      className={`${styles.contactInput}`}
                      type="email"
                      placeholder="Email"
                      name="email"
                      required
                      onChange={(event) => setEmail(event.currentTarget.value)}
                    />
                    <label className={`${styles.contactFormIcon}`}>
                      <Image src={atIcon} />
                    </label>
                  </Box>
                </Flex>
                <Flex justifyContent="center" py={3} flexWrap="wrap">
                  <BrandButtonA
                    className={`${globalStyles.btn}`}
                    onClick={onResendClick}
                    style={{
                      padding: "12px 30px",
                      backgroundColor: "#AA63FF",
                      color: "#FFFFFF",
                      boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)",
                      marginRight: "8px",
                    }}
                  >
                    Resend email
                  </BrandButtonA>
                  <BrandButton
                    className={`${globalStyles.btn}`}
                    to="/contact-us"
                    style={{
                      cursor: "pointer",
                      padding: "12px 30px",
                      backgroundColor: "rgba(170, 99, 255, .2)",
                      color: "#AA63FF",
                    }}
                  >
                    Contact us
                  </BrandButton>
                </Flex>
                {isError && (
                  <Text color="#e74c3c" fontWeight="700" textAlign="center">
                    Something went wrong
                  </Text>
                )}
                {isSuccessful && (
                  <Text color="#279D74" fontWeight="700" textAlign="center">
                    Verification email sent
                  </Text>
                )}
              </HeroLayoutEmpty>
            </HeroGridCard>
          </PageSection>
        </HeroGrid>
      </Page>
    </IndexLayout>
  );
};

export default VerifyEmailPage;
